import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { App as AntdApp, ConfigProvider } from 'antd';
import { RootStoreContext } from './root-store-context';
import RootStore from './stores/root-store';
import Router from './Routes/Router';


const App: React.FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorLink: "#5a7890",
          colorLinkHover: "#5a7890",
          colorLinkActive: "#5a7890",
        },
        components: {
          Menu: {
            colorBgContainer: '#FFFFFF',
            colorText: "#e6f4ff",
            itemSelectedColor: "#5a7890",
            fontSize: 14,
            itemSelectedBg: "#CBD8E2",
            itemColor: "#5a7890",
            itemHoverBg: "#CBD8E2",
            itemHoverColor: "#5a7890",
            colorBgLayout: "#FFFFFF",
            borderRadius: 0,
            borderRadiusLG: 0,
            itemMarginBlock: 0,
            itemMarginInline: 0
          },
          Input: {
            colorBgContainer: '#FFFFFF',
            borderRadius: 6
          },
          Form: {
            size: 13,
          },
          Button: {
            borderRadius: 17,
            colorPrimary: "rgba(47, 75, 98, 1)",
            colorPrimaryHover: "#5a7890",
            colorPrimaryActive: "#5a7890",
            colorPrimaryTextActive: "#ffffff",
          },
          Table: {
            cellPaddingBlock: 12,
            cellPaddingInline: 8,
            cellFontSizeSM: 13,
            cellPaddingInlineSM: 4,
            headerBg: "rgba(105, 105, 105, 0.30)",
            headerSortActiveBg: "rgba(105, 105, 105, 0.40)",
            headerSortHoverBg: "rgba(105, 105, 105, 0.45)",
            rowHoverBg: "rgb(235, 235, 235)",
            borderColor: "rgba(105, 105, 105, 0.2)",
            bodySortBg: "rgba(255, 255, 255, 0)",
            colorPrimary: "rgb(30, 42, 52)"
          },
          Layout: {
            siderBg: "#FFFFFF",
          },
          Descriptions: {
            itemPaddingBottom: 0,
          }
        },
      }}
    >

      <RootStoreContext.Provider value={new RootStore()}>
        <AntdApp>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AntdApp>
      </RootStoreContext.Provider>
    </ConfigProvider>
  );
};

export default App;